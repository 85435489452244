.headline
{  font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: bold}

.headline1
{  font-family: Arial, Helvetica, sans-serif; font-size: 16px; font-weight: bold}

.subheads
{  font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: bold; text-align: left}

body
{  margin: 0 0 0 0;
font-family: Arial, Helvetica, sans-serif; font-size: 12px; text-align: left; background-image:none;}

.mapImage{
	margin-left:3px;
}
.bold
{  font-weight: bold; }

.small
{  font-size: 10px}

.verysmall
{  font-size: 9px}

.linespace
{  font-size: 3px; line-height: 4px}

table
{  font-family: Arial, Helvetica, sans-serif; font-size: 12px; background-position: center top; text-align: left; vertical-align: top}

td
{  font-family: Arial, Helvetica, sans-serif; font-size: 12px; text-align: left; vertical-align: top}

a:link
{  text-decoration: underline}

a:hover
{  text-decoration: none;
}

p {  font-family: "Arial", "Helvetica", "sans-serif"; font-size: 12px}

.numbers {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 36px;
	font-weight: bold;
	color: #000066;
	font-style: normal;
	line-height: 36px;
	letter-spacing: -0.3em;
}
.arbLogo {
	/*float:right;*/
	margin-bottom:18px;
	margin-right:1px;
	/*margin-top:-16px;*/
}
#languageToggle {
	display:block;
	float:left;
	margin:12px 0 0 20px;
}
